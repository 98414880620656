import React from "react";
import * as d3 from "d3";
import { useD3 } from "./hooks/useD3";

export default function PieChart({
  data,
  label,
}: {
  data: { name: string; value: number }[];
  label: string;
}) {
  const ref = useD3(
    (svg) => {
      const { width, height } = svg.node().getBoundingClientRect();
      svg.attr("viewBox", [-width / 2, -height / 2, width, height]);
      const pie = d3
        .pie()
        .sort(null)
        .value((d: any) => d.value);
      const color = d3
        .scaleOrdinal()
        .domain(data.map((d) => d.name))
        .range(
          d3
            .quantize((t) => d3.interpolateSpectral(t * 0.8 + 0.1), data.length)
            .reverse(),
        );
      const arcs = pie(data);
      const arc = d3
        .arc()
        .innerRadius(0)
        .outerRadius(Math.min(width, height) / 2 - 1);
      const radius = (Math.min(width, height) / 2) * 0.8;
      const arcLabel = d3.arc().innerRadius(radius).outerRadius(radius);
      svg
        .append("g")
        .attr("stroke", "white")
        .selectAll("path")
        .data(arcs)
        .join("path")
        .attr("fill", (d) => color(d.data.name))
        .attr("d", arc)
        .append("title")
        .text((d) => `${d.data.name}: ${d.data.value.toLocaleString()}`);
      svg
        .append("g")
        .attr("font-family", "sans-serif")
        .attr("font-size", 12)
        .attr("text-anchor", "middle")
        .selectAll("text")
        .data(arcs)
        .join("text")
        .attr("transform", (d) => `translate(${arcLabel.centroid(d)})`)
        .call((text) =>
          text
            .append("tspan")
            .attr("y", "-0.4em")
            .attr("font-weight", "bold")
            .text((d) => d.data.name),
        )
        .call((text) =>
          text
            .filter((d) => d.endAngle - d.startAngle > 0.25)
            .append("tspan")
            .attr("x", 0)
            .attr("y", "0.7em")
            .attr("fill-opacity", 0.7)
            .text((d) => d.data.value.toLocaleString() + "%"),
        );
    },
    [data.length],
  );

  return (
    <svg
      ref={ref}
      style={{
        height: 400,
        width: "100%",
      }}
    />
  );
}
