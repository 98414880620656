import * as React from "react";
import {
  AmbientLight,
  BoxGeometry,
  Camera,
  Color,
  DirectionalLight,
  Mesh,
  MeshLambertMaterial,
  MeshPhongMaterial,
  PerspectiveCamera,
  Plane,
  Renderer,
  Scene,
  Vector3,
  Vector4,
  WebGLRenderer,
} from "three";
// TODO: for whatever reason the svg is rendered upside down so we're loading a flipped version
import logo from "../../assets/images/logoUpsideDown.svg";
import { ExtrudeGeometryOptions } from "three/src/geometries/ExtrudeGeometry";
import parseSvg from "./three/parseSvg";
import Stats from "./three/stats";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import { ShadowMesh } from "three/addons/objects/ShadowMesh.js";

const PLANE_POSITION_Y = -151;
const aspect = window.innerWidth / window.innerHeight;
const camera = new PerspectiveCamera(45, aspect, 20, 3000);
const scene = new Scene();
// @ts-ignore
const stats = new Stats();
const material = new MeshPhongMaterial({ color: 0xfbbf24, emissive: 0x000020 });
const dirLight = new DirectionalLight(0xffffff, 0.6);
const renderer = new WebGLRenderer({ antialias: true });
const extrudeSettings: ExtrudeGeometryOptions = {
  depth: 60,
  steps: 1,
  bevelEnabled: false,
};
const planeConstant = PLANE_POSITION_Y + 1; // this value must be slightly higher than the groundMesh's y position
const groundPlane = new Plane(new Vector3(0, 1, 0), planeConstant);
const lightPosition4D = new Vector4();

const HomeAnimation = () => {
  camera.position.set(0, 0, -2000);
  scene.background = new Color(0x111827);
  const planeMesh = new Mesh(
    new BoxGeometry(2000, 0.01, 2000),
    new MeshLambertMaterial({ color: 0x999999 }),
  );
  planeMesh.position.y = PLANE_POSITION_Y;
  scene.add(planeMesh);
  scene.add(new AmbientLight(0xffb55c, 0.2));
  scene.add(dirLight);
  dirLight.position.set(0, 20, -30);
  scene.add(dirLight);
  // amount of light-ray divergence. Ranging from:
  // 0.001 = dirLight(min divergence) to 1.0 = pointlight(max divergence)
  lightPosition4D.set(
    dirLight.position.x,
    dirLight.position.y,
    dirLight.position.z,
    0.001,
  );
  const group = parseSvg(logo, material, extrudeSettings);
  scene.add(group);
  const objectShadows: ShadowMesh[] = group.children.map(
    (c) => new ShadowMesh(c),
  );
  objectShadows.map((s) => group.add(s));
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  const content = document.getElementsByClassName("content")[0];
  const element = renderer.domElement;
  element.style.margin = "0 auto";
  content.appendChild(stats.dom);
  content.appendChild(element);
  window.addEventListener("resize", onWindowResize);
  const controls = newOrbitControls(camera, renderer);
  animate();

  function animate() {
    requestAnimationFrame(animate);
    render();
  }

  function render() {
    controls.update(); // only required if controls.enableDamping = true, or if controls.autoRotate = true
    renderer.render(scene, camera);
    objectShadows.forEach((s) => s.update(groundPlane, lightPosition4D));
    stats.update();
  }

  return <></>;
};

function newOrbitControls(camera: Camera, renderer: Renderer): OrbitControls {
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.listenToKeyEvents(window); // optional
  controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
  controls.dampingFactor = 0.05;
  controls.screenSpacePanning = false;
  controls.minDistance = 200;
  controls.maxDistance = 1000;
  controls.maxPolarAngle = Math.PI / 2;
  return controls;
}

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
}

export default HomeAnimation;
